import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module"; // 🔹 Importamos GTM

const tagManagerArgs = {
  gtmId: "GTM-P68LC7GZ", // 🔹 Reemplázalo con tu ID de GTM
};

// 🔹 Inicializamos GTM antes de renderizar la app
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// 🔹 Reporte de métricas opcional
reportWebVitals();
